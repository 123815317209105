<template lang="pug">
  v-card
    v-card-head
      v-card-head-label
        h2.modal-title {{ 'pages.administration.modify_default_language' | translate }}
    v-card-content
      p {{ 'pages.administration.this_setting_will_change_the_default_language_message' | translate }}
      v-select(
        v-if="!loading"
        single
        hideBottomMargin
        :placeholder="'ui.labels.language' | translate"
        track-by="title"
        item-value="id"
        :options="options"
        v-model="lang")
      v-skeleton(v-else width="100%" )
    v-card-actions
      v-btn(
        text
        @click="$emit('close')") {{ 'ui.buttons.cancel' | translate }}
      v-btn(
        @click="onConfirmLang") {{ 'ui.buttons.confirm' | translate }}

</template>

<script>
import api from '@/api'

export default {
  name: 'ChangeLangModal',

  props: {
    node: Object
  },

  async mounted () {
    const { data } = await api.getLanguages()
    this.options = data
    this.lang = this.options.find(el => el.id === (this.node.data.defaultLanguage.id || 'en')) || null
    this.loading = false
  },

  data: () => ({
    options: [],
    lang: null,
    loading: true
  }),

  methods: {
    onConfirmLang () {
      if (this.lang && this.lang.id) {
        this.$emit('on-language-update', this.lang, this.node)
      }
    }
  }

}
</script>

<style lang="scss">
</style>
